import React from 'react';

import Container from '@nib-components/container';
import Heading from '@nib-components/heading';
import { Stack, Columns, Column } from '@nib/layout';

import TextboxFormControl from '../FormControl/TextboxFormControl';
import { BaseFormikProps, sharedColumnSpace } from './IwiFormValues';

interface ReferralNameFormSectionProps extends BaseFormikProps {}

const ReferralNameFormSection = (props: ReferralNameFormSectionProps): JSX.Element => {
  const { touched, values } = props;
  const { referralName } = values;

  return (
    <Container>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Person who referred me
        </Heading>
        <Columns space={sharedColumnSpace} collapseBelow="md">
          <Column width="2/5">
            <TextboxFormControl
              id="referral-name"
              value={referralName}
              isTouched={touched.referralName}
              fieldName="referralName"
              label="Full name of member"
              isEmptyAndOptional={true}
              valid={true}
              {...props}
            />
          </Column>
        </Columns>
      </Stack>
    </Container>
  );
};

export default ReferralNameFormSection;
