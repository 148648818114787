import React from 'react';
import { pullAt } from 'lodash';
import Container from '@nib-components/container';
import Divider from '@nib-components/divider';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { SecondaryButton } from '@nib-components/button';
import { Box, Stack, Columns, Column, Inline } from '@nib/layout';
import { AddSystemIcon } from '@nib/icons';

import TextboxFormControl from '../FormControl/TextboxFormControl';
import DateTextboxFormControl from './controls/DateTextboxFormControl';
import RadioButtonFormControl from './controls/RadioButtonFormControl';
import { isEmpty, isInvalidDate, isInvalidGender } from '../../utils/form-validation';
import { addNonMemberDetails, BaseFormikProps, sharedColumnSpace } from './IwiFormValues';

const isValidField = (errors: any, index: number, fieldName: string): boolean => {
  if (errors?.nonMemberDetails?.[index]?.[fieldName]) {
    return false;
  }
  return true;
};

const getErrorMessage = (errors: any, index: number, fieldName: string): string | undefined => {
  if (errors?.nonMemberDetails?.[index]?.[fieldName]) {
    return errors.nonMemberDetails[index][fieldName];
  }
  return undefined;
};

interface NonMemberDetailsFormProps extends BaseFormikProps {}

const NonMemberDetailsFormSection = (props: NonMemberDetailsFormProps): JSX.Element => {
  const { touched, values, setFieldValue, errors } = props;
  const { nonMemberDetails } = values;

  return (
    <Container>
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Non-member details
        </Heading>
        <Copy measure={false}>
          If you want to add whānau who are not registered Ngāti Whātua Ōrākei hapū members please
          fill out their details and someone from nib will contact you:
        </Copy>
        {nonMemberDetails.map((nonMember, index) => {
          const { fullName, dateOfBirth, gender } = nonMember;
          return (
            <Stack space={4} key={index}>
              <Columns space={sharedColumnSpace} collapseBelow="md">
                <Column width={{ lg: '2/5', xxl: '1/2' }}>
                  <TextboxFormControl
                    id={`non-member-full-name-${index}`}
                    value={fullName}
                    isTouched={touched?.nonMemberDetails?.[index]?.fullName}
                    fieldName={`nonMemberDetails[${index}].fullName`}
                    label="Full name"
                    isEmptyAndOptional={true}
                    valid={isValidField(errors, index, 'fullName')}
                    error={getErrorMessage(errors, index, 'fullName')}
                    {...props}
                  />
                </Column>
                <Column width="1/4">
                  <DateTextboxFormControl
                    id={`non-member-date-of-birth-${index}`}
                    value={dateOfBirth}
                    isTouched={touched?.nonMemberDetails?.[index]?.dateOfBirth}
                    fieldName={`nonMemberDetails[${index}].dateOfBirth`}
                    label="Date of Birth"
                    isEmptyAndOptional={true}
                    valid={isValidField(errors, index, 'dateOfBirth')}
                    error={getErrorMessage(errors, index, 'dateOfBirth')}
                    {...props}
                  />
                </Column>
                <Column width={{ lg: '2/5', xxl: '1/4' }}>
                  <RadioButtonFormControl
                    id={`non-member-gender-${index}`}
                    value={gender}
                    isTouched={touched?.nonMemberDetails?.[index]?.gender}
                    fieldName={`nonMemberDetails[${index}].gender`}
                    label="Gender"
                    options={{ male: 'Male', female: 'Female' }}
                    showButtonStyle={true}
                    width="100%"
                    valid={isValidField(errors, index, 'gender')}
                    error={getErrorMessage(errors, index, 'gender')}
                    {...props}
                  />
                </Column>
              </Columns>

              <Box textAlign="right">
                <SecondaryButton
                  onClick={() => {
                    pullAt(nonMemberDetails, index);
                    setFieldValue('nonMemberDetails', nonMemberDetails);
                  }}
                >
                  Remove
                </SecondaryButton>
              </Box>
              <Divider />
            </Stack>
          );
        })}
        <SecondaryButton
          onClick={() =>
            setFieldValue('nonMemberDetails', [...nonMemberDetails, addNonMemberDetails()])
          }
          disabled={nonMemberDetails.length >= 20}
        >
          <Inline space={3} verticalAlign="center" align="center">
            <AddSystemIcon />
            <Copy>Add a non-member</Copy>
          </Inline>
        </SecondaryButton>
      </Stack>
    </Container>
  );
};

export const validateNonMembers = (errors, nonMembers) => {
  const nonMembersArrayErrors: any[] = [];
  nonMembers &&
    nonMembers.forEach((nonMember, nonMemberIndex) => {
      const nonMemberErrors: any = {};

      /*
       * The form on initial render displays a row of non-member inputs that are optional
       * unless one of the inputs has been correctly filled in.
       */
      const shouldValidateNonMember = !(
        isEmpty(nonMember.fullName) &&
        isInvalidDate(nonMember.dateOfBirth) &&
        isInvalidGender(nonMember.gender)
      );

      if (shouldValidateNonMember) {
        if (isEmpty(nonMember.fullName)) {
          nonMemberErrors.fullName = 'Please provide the full name';
        }
        if (isInvalidDate(nonMember.dateOfBirth)) {
          nonMemberErrors.dateOfBirth = 'Please enter date of birth';
        }
        if (isInvalidGender(nonMember.gender)) {
          nonMemberErrors.gender = 'Please select a gender';
        }
        if (Object.keys(nonMemberErrors).length !== 0) {
          nonMembersArrayErrors[nonMemberIndex] = nonMemberErrors;
        }
      }
    });
  if (nonMembersArrayErrors.length) {
    errors.nonMemberDetails = nonMembersArrayErrors;
  }
};

export default NonMemberDetailsFormSection;
